<template>
    <div class="mini" id="mini">
        <div style="text-align: center; position: relative; top: 1.083rem;">
            <div style="width: 1.8rem; height: 1.8rem; border-radius: 50%; overflow: hidden; display: inline-block;">
                <van-image
                    :src="info.headImgUrl"
                    style="width: 100%;"
                />
            </div>
        </div>
        <div class="user-info">
            <div class="user">
                <van-image
                    width="22"
                    height="22"
                    :src="require('@/assets/images/user.png')"
                    style="margin-right: 2.6%; vertical-align: middle;"
                />
                {{ info.username }}
            </div>
            <div class="role">
                <van-image
                    width="22"
                    height="22"
                    :src="require('@/assets/images/role.png')"
                    style="margin-right: 2.6%; vertical-align: middle;"
                />
                {{ info.alias }}
            </div>
            <div class="company">
                <van-image
                    width="22"
                    height="22"
                    :src="require('@/assets/images/company.png')"
                    style="margin-right: 2.6%; vertical-align: middle;"
                />
                {{ info.orgName }}
            </div>
            <div class="phone">
                <div style="width: 75%; display: inline-block; vertical-align: middle;">
                    <p style="margin: 0; padding: 0; font-size: 16px;">
                        <span>服务电话：010-53652098</span>
                    </p>
                    <p style="margin: 0; padding: 0; font-size: 13px; margin-top: 5px; margin-bottom: 5px;">
                        <span style="color: rgba(255, 255, 255, .5);">周一至周五(9:30-18:00)</span>
                    </p>
                </div>
                <a href="tel:010-53652098">
                    <van-image
                        style="width: 12%; position: absolute; right: 17%;"
                        :src="require('@/assets/images/tophone.png')"
                    />
                </a>
            </div>
            <div class="signout">
                <van-button block type="info" color="#1DC6BF" class="btn" @click="signout">退出</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { request } from "@/api/service";
import store from '@/store'
import { authorizeLogin, authorize, isWxAuthorize } from '@/utils/weixin'
import { mapState } from "vuex";
import { Dialog } from 'vant'
export default {
    data () {
        return {
        }
    },
    computed: {
        ...mapState("user", ["info"]),
    },
    mounted () {
        mini.style.height = (document.documentElement.clientHeight - 60) + 'px'
    },
    methods: {
        signout () {
            Dialog.confirm({
                title: '提示',
                message: '确定要退出当前账号吗？',
            }).then(() => {
                request({
                    url: "/afis-auth/auth/app/offline",
                    method: "get",
                }).then(res => {
                    let thirdId = store.getters.info.thirdId
                    store.dispatch('user/set', null)
                    this.$router.push({
                        path: '/login',
                        query: {
                            thirdId: thirdId
                        }
                    })
                })
            }).catch(() => {
                // on cancel
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.mini {
    background-image: url('../../assets/images/mine_back.png');
    background-size: 101% 101%;
    background-repeat: no-repeat;
    .user-info {
        width: 100%;
        position: absolute;
        top: 4.7rem;
        color: #fff;
        margin: 0 auto;
        .user {
            width: 80%;
            margin: 0 auto;
            font-size: 0.32rem;
        }
        .role {
            width: 80%;
            border-bottom: 1px solid #fff;
            padding-bottom: 2.1%;
            font-size: 0.32rem;
            margin: 0 auto;
            margin-top: 2%;
        }
        .company {
            width: 80%;
            border-bottom: 1px solid #fff;
            padding-bottom: 2.1%;
            font-size: 0.32rem;
            margin: 0 auto;
            margin-top: 2%;
        }
        .phone {
            width: 80%;
            border-bottom: 1px solid #fff;
            padding-bottom: 2.1%;
            margin: 0 auto;
            margin-top: 2%;
        }
        .signout {
            margin-top: 0.45rem;
            .btn {
                width: 3.75rem;
                height: 40px;
                margin: 0 auto;
                border-color: #fff !important;
            }
        }
    }
}
</style>